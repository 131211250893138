/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */

/**
 * GENERAL:
 *
 * This DataLayer class is meant for pushing data to Google datalayer.
 *
 * Usage:
 *
 * 2) Call this.$dataLayer.PushToECommerce({ params }); to push data to datalayer
 *
 * Currently, this.$dataLayer.PushToECommerce is called from Application.vue since
 * beforeRouteEnter and beforeRouteUpdate are called on every route change.
 *
 *
 * MOTIVATION:
 *
 * By using this type of class design approach, it is possible to construct
 * datalayer objects in a way it's modular, readable, and it supports future
 * development.
 *
 *
 * RULES:
 *
 * 1) COMMENT YOUR CODE!
 *
 */

import { date } from '@shared/filters';
import store from '@shared/store';

class DataLayer {
  /**
   * @param {Object} params
   * @param {String} params.action - Action parameter, e.g. start
   * @param {String} params.formData - Additional data
   */
  constructor({ params }) {
    this._action = params.action;
    this._formData = params.formData;
    this._params = params;
    this._store = store;

    // Make sure window.dataLayer exists
    window.dataLayer = window.dataLayer || [];

    this._dataLayer = window.dataLayer;
  }

  // ========== STATIC ==========
  /**
   * This static method takes in Vue Router to object, creates
   * a dataLayer, gets data for a given to.params.action, and
   * pushes that data to window.dataLayer.
   *
   * @param {Object} to - Vue Router to object
   * @constructor
   */
  static PushToECommerce({ params }) {
    if (process.env.VUE_APP_TEST_CASE === 'ui') return;

    const dataLayer = new DataLayer({ params });
    const data = dataLayer.getData();

    dataLayer.push(data);
  }
  // ========== STATIC ENDS ==========

  // ========== GETTERS ==========
  /**
   * Returns action that is derived from Vue Router to object (to.params.action).
   *
   * E.g. start, basicInformation, customerQuestions
   *
   * @returns {String}
   */
  get action() {
    return this._action;
  }

  get formData() {
    return this._formData;
  }

  get params() {
    return this._params;
  }

  /**
   * Returns an actionField for each step.
   *
   * E.g. For the instalmentLoan's first step (start), the actionField would be "{}".
   *
   * @returns {Object}
   */
  get actionField() {
    const actionFields = {
      instalmentLoan: {
        detail: {},
        add: {},
        checkout_1: {
          step: 1,
        },
        checkout_2: {
          step: 2,
        },
        checkout_3: {
          step: 3,
        },
        checkout_3b: {
          step: '3b',
        },
        checkout_3c: {
          step: '3c',
        },
        checkout_4: {
          step: 4,
        },
        checkout_5: {
          step: 5,
        },
        purchase: {
          id: this.loanId, // Loan Id
          revenue: this.revenue, // Final revenue
        },
      },
      continuousLoan: {
        detail: {},
        add: {},
        checkout_1: {
          step: 1,
        },
        checkout_2: {
          step: 2,
        },
        checkout_3: {
          step: 3,
        },
        checkout_3b: {
          step: '3b',
        },
        checkout_3c: {
          step: '3c',
        },
        checkout_4: {
          step: 4,
        },
        checkout_5: {
          step: 5,
        },
        purchase: {
          id: this.loanId, // Loan Id
          revenue: this.withdrawalAmount, // Selected first withdrawal amount
        },
      },
      brokeredContinuousLoan: {
        detail: {},
        add: {},
        checkout_1: {
          step: 1,
        },
        checkout_2: {
          step: 2,
        },
        checkout_3: {
          step: 3,
        },
        checkout_3b: {
          step: '3b',
        },
        checkout_3c: {
          step: '3c',
        },
        checkout_4: {
          step: 4,
        },
        checkout_5: {
          step: 5,
        },
        checkout_6: {
          step: 6,
        },
        purchase: {
          id: this.loanId, // Loan Id
          revenue: this.withdrawalAmount, // Selected first withdrawal amount
        },
      },
      brokeredInstalmentLoan: {
        detail: {},
        add: {},
        checkout_1: {
          step: 1,
        },
        checkout_2: {
          step: 2,
        },
        checkout_3: {
          step: 3,
        },
        checkout_4: {
          step: 4,
        },
        checkout_5: {
          step: 5,
        },
        checkout_6: {
          step: 6,
        },
        purchase: {
          id: this.loanId, // Loan Id
          revenue: this.withdrawalAmount, // Selected first withdrawal amount
        },
      },
    };

    return actionFields[this.loanType][this.key];
  }

  /**
   * Returns application appliedInstalmentAmount.
   *
   * E.g. 14900.
   *
   * @returns {Number}
   */
  get appliedInstalmentAmount() {
    return DataLayer.getInEuros(this.initialData.appliedInstalmentAmount);
  }

  get appliedInstalmentCount() {
    return this.initialData.appliedInstalmentCount || '';
  }

  /**
   * Returns application appliedLoanLimit.
   *
   * E.g. 2000.00.
   *
   * @returns {Number}
   */
  get appliedLoanLimit() {
    return DataLayer.getInEuros(this.initialData.appliedLoanLimit);
  }

  /**
   * Returns appliedLoanlimit or withdrawalAmount depending on the loan type.
   *
   * E.g. 250.00
   *
   * @returns {Number}
   */
  get loanSum() {
    if (this.initialData.currentPage === 'complete'
      && this.product.loanType === 'instalmentLoan') {
      // Return appliedLoanLimit if applied for instalmentLoan but got continuousLoan offer
      return this.appliedLoanLimit;
    }
    if (this.initialData.currentPage === 'complete') {
      // Return initial withdrawal amount if loanType has not changed
      return this.initialWithdrawalAmount;
    }
    if (this.appliedLoanLimit !== '') {
      return this.appliedLoanLimit;
    }
    return this.initialWithdrawalAmount;
  }

  get initialLoanAmount() {
    if (this.product.loanType === 'instalmentLoan') {
      return this.appliedLoanLimit;
    }
    return this.initialWithdrawalAmount;
  }

  /**
   * Returns initial withdrawalAmount if available.
   *
   * E.g. 200000.
   *
   * @returns {Number}
   */
  get initialWithdrawalAmount() {
    if (this.product.loanType !== 'instalmentLoan') {
      if (!this.initialData.initialWithdrawalAmount) {
        return DataLayer.getInEuros(this.initialData.withdrawalAmount);
      }
      return DataLayer.getInEuros(this.initialData.initialWithdrawalAmount);
    }
    return '';
  }

  /**
   * Returns application withdrawalAmount.
   *
   * E.g. 200000.
   *
   * @returns {Number}
   */
  get withdrawalAmount() {
    if (this.initialData.currentPage === 'complete') {
      return DataLayer.getInEuros(this.initialData.withdrawalAmount);
    }
    return '';
  }

  /**
   * Returns application brand.
   *
   * E.g. "saldo".
   *
   * @returns {String}
   */
  get brand() {
    const brandSites = [
      'limiitti.fi',
      'laina.com',
      'saldo.com',
      'vippi.fi',
    ];
    const initialData = this.store.getters['application/getInitialData'];
    let saleChannel = initialData.saleChannel || '';
    saleChannel = saleChannel.toLowerCase();

    if (brandSites.indexOf(saleChannel) !== -1) {
      return saleChannel.split('.')[0];
    }

    if (saleChannel === 'broker') {
      return this.store.getters['application/getBrand'];
    }

    return '';
  }

  /**
   * Returns true if purchase event should be sent.
   *   *
   * @returns {String}
   */
  get purchase() {
    return this.store.getters['application/getPurchase'];
  }

  /**
   * Returns instalment loan offers.
   *
   * @returns {Array}
   */
  get offers() {
    return this.store.getters['application/getLoanOffers'];
  }

  /**
   * Returns instalment brokered offers.
   *
   * @returns {Array}
   */
  get brokeredOffers() {
    return this.store.getters['application/getBrokeredOffers'];
  }

  /**
   * Returns "private" dataLayer array. This is actually
   * window.dataLayer as defined in the constructor.
   *
   * @returns {Array}
   */
  get dataLayer() {
    return this._dataLayer;
  }

  /**
   * Returns default product dimensions for GTM
   */
  get defaultDimensions() {
    return {
      dimension10: this.loanType, // initial product name in english
      dimension11: this.productName, // initial product name in finnish
      dimension12: this.brand, // initial brand
      dimension13: this.initialRevenue, // initial revenue
      dimension14: this.appliedInstalmentAmount, // initial instalment amount
      dimension15: this.initialWithdrawalAmount, // initial withdrawal amount
      dimension16: this.initialLoanAmount, // initial total amount of loan
    };
  }

  /**
   * Returns b2b default product dimensions for GTM
   */
  get businessLoanDefaultDimensions() {
    return {
      dimension10: 'businessInstalmentLoan',
      dimension11: 'yrityslaina',
      dimension12: 'saldo',
      dimension13: this.appliedLoanLimit,
      dimension14: '',
      dimension15: '',
      dimension16: this.appliedLoanLimit,
      dimension17: this.appliedInstalmentCount,
    };
  }

  get additionalDimensions() {
    return {
      dimension20: this.selectedOffer.type || '', // Offered loan type
      dimension21: this.selectedOfferProductName, // Offered loan type in Finnish
      dimension22: this.brand, // Brand property
      dimension23: this.selectedOffer.type === 'instalmentLoan' ? this.selectedLoanLimit : '',
      dimension24: this.finalVariant, // Final variant
      dimension25: '', // N/A
      dimension26: this.selectedLoanLimit, // Offered loan limit
      dimension27: this.selectedOffer.numberOfInstalments || '', // Offered number of instalments
      dimension28: this.selectedInstalmentAmount, // Offered instalment amount
    };
  }

  /**
   * Returns default properties for validation events
   */
  get validationProperties() {
    return {
      stepURL: this.initialData.currentPage,
      ...this.defaultDimensions,
      ...this.additionalDimensions,
    };
  }

  /**
   * Returns default properties for b2b validation events
   */
  get b2bValidationProperties() {
    return {
      stepURL: this.initialData.currentPage,
      ...this.businessLoanDefaultDimensions,
      ...this.businessLoanAdditionalDimensions,
    };
  }

  /**
   * Returns a default product object. This is used in most steps, hence the default.
   *
   * E.g. {
   *   name: 'Joustoluotto',
   *   price: 200000,
   *   category: '',
   *   variant: 14900,
   *   brand: 'saldo',
   * };
   *
   * @returns {{name: String, price: Number, category: String, variant: Number, brand: String}}
   */
  get defaultProduct() {
    return {
      name: this.productName, // (joustoluotto/kulutusluotto)
      price: this.loanSum,
      category: this.loanType, // Initial product type
      variant: this.appliedInstalmentAmount, // Monthly instalment amount
      brand: this.brand, // brand
      quantity: 1,
      ...this.defaultDimensions,
    };
  }

  /**
    * name: initial loan type in Finnish
    * price: initial revenue
    * category: initial loan type in English
    * variant: initial withdrawal or instalment amount
    * brand: initial brand
    */
  get defaultBusinessLoanProduct() {
    return {
      name: 'yrityslaina',
      price: this.appliedLoanLimit,
      category: 'businessInstalmentLoan',
      variant: this.appliedInstalmentCount,
      brand: 'saldo',
      quantity: 1,
      ...this.businessLoanDefaultDimensions,
    };
  }

  get businessLoanAdditionalDimensions() {
    return {
      dimension20: 'businessInstalmentLoan',
      dimension21: 'yrityslaina',
      dimension22: 'saldo',
      dimension23: this.selectedLoanLimit,
      dimension24: this.selectedInstalmentAmount,
      dimension25: '',
      dimension26: this.selectedLoanLimit,
      dimension27: this.selectedNumberOfInstalments,
      dimension28: this.selectedInstalmentAmount,
    };
  }

  get defaultBusinessLoanCheckoutObject() {
    return {
      event: 'eec.b2b.checkout',
      visitorType: this.customerType,
      ecommerce: {
        currencyCode: 'EUR',
        checkout: {
          actionField: {
            step: 1,
          },
          products: [
            this.defaultBusinessLoanProduct,
          ],
        },
      },
    };
  }

  get finalVariant() {
    if (this.selectedOffer.type === 'instalmentLoan') {
      return this.selectedInstalmentAmount;
    }
    return this.selectedLoanLimit;
  }

  /**
   * Returns application deliveryFee.
   *
   * E.g. 60000.
   *
   * @returns {Number}
   */
  get deliveryFee() {
    return DataLayer.getInEuros(this.selectedOffer.deliveryFee);
  }

  /**
   * Creates and returns event string for the datalayer
   * objects. This is as per the specification.
   *
   * E.g. "eec.detail" or "eec.add".
   *
   * @returns {String}
   */
  get event() {
    return `eec.${this.key}`;
  }

  /**
   * Returns revenue depending on selected offer loan type.
   */
  get initialRevenue() {
    if (this.loanType === 'instalmentLoan') {
      return this.appliedLoanLimit;
    }
    return this.initialWithdrawalAmount;
  }

  /**
   * Returns revenue depending on selected offer loan type.
   */
  get revenue() {
    if (this.selectedOffer.type === 'instalmentLoan') {
      return this.key === 'purchase' ? this.selectedLoanLimit : this.appliedLoanLimit;
    }
    return this.withdrawalAmount;
  }

  /**
   * Returns payment schedule
   */
  get paymentSchedule() {
    const { nightWithdrawalTime, paymentSchedule } = this.initialData;

    const isNow = parseInt(paymentSchedule, 10) === 0;
    const isNightWithdrawal = nightWithdrawalTime === undefined;

    if (isNow && !isNightWithdrawal) {
      const formattedTime = date(nightWithdrawalTime, {
        type: 'time',
      });

      return `Klo ${formattedTime}.`;
    }

    if (isNow) return 'Noin 5 minuutissa.';

    return `${paymentSchedule} tunnin kuluttua.`;
  }

  /**
   * Creates and returns event string for the datalayer objects.
   *
   * Since the specification included the same key for two different steps,
   * I had to implement a dedicated method for the checkout steps.
   *
   * E.g. "eec.checkout".
   *
   * @returns {String}
   */
  get eventForCheckout() {
    return `eec.${this.keyForCheckout}`;
  }

  /**
   * Returns application initialData.
   *
   * No example.
   *
   * @returns {Object}
   */
  get initialData() {
    return this.store.getters['application/getInitialData'];
  }

  /**
   * Returns product.
   *
   * No example.
   *
   * @returns {Object}
   */
  get product() {
    return this.store.getters['application/getProduct'];
  }

  /**
   * Returns selected offer's instalmentAmount.
   *
   * E.g. 14900.
   *
   * @returns {String}
   */
  get selectedInstalmentAmount() {
    if (this.selectedOffer) {
      return DataLayer.getInEuros(this.selectedOffer.instalmentAmount);
    }
    return '';
  }

  /**
   * Returns selected offer's deliveryFeeTotal.
   *
   * E.g. 2400.00.
   *
   * @returns {String}
   */
  get deliveryFeeTotal() {
    if (this.selectedOffer) {
      return DataLayer.getInEuros(this.selectedOffer.deliveryFeeTotal);
    }
    return '';
  }

  /**
   * Returns a key for each step. These keys are used
   * for creating correct datalayer objects for each step.
   *
   * E.g. For the first step of instalmentLoan (start),
   * this method would return "detail".
   *
   * @returns {String}
   */
  get key() {
    const keys = {
      instalmentLoan: {
        start: 'checkout_1',
        basicInformation: 'checkout_2',
        customerQuestions: 'checkout_3',
        kreditz: 'checkout_3b',
        bankAccountSelection: 'checkout_3c',
        loanOffer: 'instalmentLoanOffers',
        instalmentLoanTerms: 'checkout_5',
        continuousLoanTerms: 'checkout_5',
        instalmentLoanComplete: 'purchase',
        complete: 'purchase',
        reject: 'declined',
      },
      continuousLoan: {
        start: 'checkout_1',
        basicInformation: 'checkout_2',
        customerQuestions: 'checkout_3',
        kreditz: 'checkout_3b',
        bankAccountSelection: 'checkout_3c',
        loanOffer: 'continuousLoanOffers',
        continuousLoanTerms: 'checkout_5',
        complete: 'purchase',
        reject: 'declined',
      },
      brokeredContinuousLoan: {
        start: 'checkout_1',
        basicInformation: 'checkout_2',
        customerQuestions: 'checkout_3',
        kreditz: 'checkout_3b',
        bankAccountSelection: 'checkout_3c',
        loanOffer: 'continuousLoanOffers',
        continuousLoanTerms: 'checkout_5',
        complete: 'purchase',
        reject: 'declined',
      },
      brokeredInstalmentLoan: {
        start: 'checkout_1',
        basicInformation: 'checkout_2',
        customerQuestions: 'checkout_3',
        kreditz: 'checkout_3b',
        bankAccountSelection: 'checkout_3c',
        loanOffer: 'continuousLoanOffers',
        continuousLoanTerms: 'checkout_5',
        complete: 'purchase',
        reject: 'declined',
      },
    };

    // Return checkout step 4 if session is not identified and currentPage returns a terms step
    if (!this.initialData.isSessionIdentified
      && (this.action === 'continuousLoanTerms' || this.action === 'instalmentLoanTerms')) {
      return 'checkout_4';
    }

    return keys[this.loanType][this.action];
  }

  /**
   * Creates and returns a key for the checkout steps.
   *
   * Since the specification included the same key for two different
   * steps, I had to implement a dedicated method for the checkout steps.
   *
   * @returns {String}
   */
  get keyForCheckout() {
    return this.key.split('_')[0];
  }

  /**
   * Returns loan id.
   *
   * E.g. 62.
   *
   * @returns {Number}
   */
  get loanId() {
    return this.initialData.loanId;
  }

  /**
   * Returns loanLimit for the selectedOffer.
   *
   * E.g. 400000.
   *
   * @returns {Number}
   */
  get selectedLoanLimit() {
    if (this.selectedOffer) {
      return DataLayer.getInEuros(this.selectedOffer.loanLimit);
    }
    return '';
  }

  /**
   * Returns application loanType.
   *
   * E.g. "instalmentLoan" or "continuousLoan".
   *
   * @returns {String}
   */
  get loanType() {
    return this.store.getters['application/getLoanType'] || '';
  }

  /**
   * Returns the numberOfInstalments for the selectedOffer.
   *
   * @returns {Number}
   */
  get selectedNumberOfInstalments() {
    if (!this.selectedOffer) return '';
    return this.selectedOffer.numberOfInstalments || '';
  }

  /**
   * Returns the brand of the selected offer.
   *
   * @returns {Number}
   */
  get selectedLoanBrand() {
    if (this.selectedOffer.loanProgramName) {
      return this.selectedOffer.loanProgramName.split('.')[0].toLowerCase();
    }
    return '';
  }

  /**
   * Returns a product name by loanType.
   *
   * E.g. "Kulutusluotto".
   *
   * @returns {String}
   */
  get productName() {
    const productNames = {
      instalmentLoan: 'kulutusluotto',
      continuousLoan: 'joustoluotto',
      fixedPeriodContinuousLoan: 'määräaikainen joustoluotto',
      smePosInstalmentLoan: 'myyntipiste kulutusluotto',
    };

    return productNames[this.product.gaType];
  }

  /**
   * Returns a selected offer product name by loanType.
   *
   * E.g. "Kulutusluotto".
   *
   * @returns {String}
   */
  get selectedOfferProductName() {
    const productNames = {
      instalmentLoan: 'kulutusluotto',
      continuousLoan: 'joustoluotto',
      fixedPeriodContinuousLoan: 'määräaikainen joustoluotto',
      smePosInstalmentLoan: 'myyntipiste kulutusluotto',
    };

    return productNames[this.selectedOffer.type] || '';
  }

  /**
   * Returns the chosen application offer.
   *
   * No example.
   *
   * @returns {Object}
   */
  get selectedOffer() {
    return this.store.getters['application/getSelectedOffer'];
  }

  /**
   * Returns a store object that is defined in the constructor.
   *
   * No example.
   *
   * @returns {Object}
   */
  get store() {
    return this._store;
  }

  /**
   * Returns customerType. Not available before tupas.
   */
  get customerType() {
    return this.initialData.currentCustomer ? 'Old' : 'New';
  }
  // ========== GETTERS ENDS ==========

  // ========== METHODS ==========
  /**
   * This method calls an appropriate action that returns data object
   * for the given step. That data object can be then pushed to the dataLayer.
   *
   * E.g. If this.action returns "start", this method would call this["start"]()
   * which equals to this.start(). This approach makes it possible to call
   * actions dynamically using the "this.action" getter.
   *
   * @returns {Object}
   */
  getData() {
    if (this[this.action]) return this[this.action]();

    return null;
  }

  /**
   * GA expects the undefined attributes to be empty strings at the very least
   * so this function attempts to find attributes that are undefined. If found,
   * it throws an error.
   *
   * @param data
   * @param key
   * @throws error
   */
  validateData(data, key = null) {
    if (typeof data === 'object' && Array.isArray(data)) {
      data.forEach((arrayItem) => {
        this.validateData(arrayItem);
      });
    } else if (Object(data) === data && !Array.isArray(data)) {
      Object.entries(data).forEach((result) => {
        const [k, value] = result;
        this.validateData(value, k);
      });
    } else if (typeof data === 'undefined') {
      throw new Error(`Undefined value for key ${key} in datalayer`);
    }
  }

  /**
   * Returns a given cents in euros with dot as delimiter and two decimals
   *
   * @param {Number} inCents
   * @returns {String}
   */
  static getInEuros(inCents) {
    if (!inCents) return '';
    return (inCents / 100).toFixed(2);
  }

  /**
   * Returns a loan type in Finnish.
   *
   * E.g. "kulutusluotto".
   *
   * @param {String} type
   * @returns {String}
   */
  static loanTypeInFinnish(type) {
    const productNames = {
      instalmentLoan: 'kulutusluotto',
      continuousLoan: 'joustoluotto',
      fixedPeriodContinuousLoan: 'määräaikainen joustoluotto',
      businessInstalmentLoan: 'yrityslaina',
      brokeredInstalmentLoan: 'kulutusluotto',
      brokeredContinuousLoan: 'joustoluotto',
    };

    return productNames[type];
  }

  /**
   * Returns the brand based on loan program name.
   *
   * @param {String} loanProgramName
   * @returns {String}
   */
  static offerBrand(loanProgramName) {
    return loanProgramName ? loanProgramName.split('.')[0].toLowerCase() : '';
  }

  /**
   * Pushes data to dataLayer.
   *
   * No example.
   *
   * @param {Object} data
   */

  /**
   * Validates and pushes data to the dataLayer. Otherwise,
   * creates error object and pushes that to the dataLayer.
   *
   * @param data
   */
  push(data) {
    if (!data) return;

    try {
      this.validateData(data); // May throw exception

      this.dataLayer.push({
        ...data,
        event: `old_${data.event}`,
      });
    } catch (e) {
      const error = {
        event: 'old_eec.error',
        errorStep: this.action,
        errorMessage: `${this.loanType} | ${e.stack}`,
        routerParams: JSON.stringify(this.params),
      };

      if (process.env.NODE_ENV === 'development') console.error(e);

      this.dataLayer.push(error);
    }
  }

  // ========== ACTIONS (STEPS) ==========
  /**
   * Returns object for "start" step. This
   * object can be pushed to the dataLayer.
   *
   * @returns {{event: String, ecommerce: {Object}}}
   */
  start() {
    // First push the add to cart event before the checkout events
    DataLayer.PushToECommerce({
      params: {
        action: 'addB2c',
      },
    });

    return {
      event: this.eventForCheckout,
      ecommerce: {
        [this.keyForCheckout]: {
          actionField: this.actionField,
          products: [
            this.defaultProduct,
          ],
        },
      },
    };
  }

  /**
   * Returns object for "basicInformation" step. This
   * object can be pushed to the dataLayer.
   *
   * @returns {{event: String, ecommerce: {Object}}}
   */
  basicInformation() {
    return {
      event: this.eventForCheckout,
      ecommerce: {
        [this.keyForCheckout]: {
          actionField: this.actionField,
          products: [
            this.defaultProduct,
          ],
        },
      },
    };
  }

  /**
   * Returns object for "customerQuestions" step. This
   * object can be pushed to the dataLayer.
   *
   * @returns {{event: String, ecommerce: {Object}}}
   */
  customerQuestions() {
    return {
      event: this.eventForCheckout,
      ecommerce: {
        [this.keyForCheckout]: { // Key: checkout
          actionField: this.actionField,
          products: [
            this.defaultProduct,
          ],
        },
      },
    };
  }

  /**
   * Returns object for "bankAccountSelection" step. This
   * object can be pushed to the dataLayer.
   *
   * @returns {{event: String, ecommerce: {Object}}}
   */
  bankAccountSelection() {
    return {
      event: this.eventForCheckout,
      ecommerce: {
        [this.keyForCheckout]: { // Key: checkout
          actionField: this.actionField,
          products: [
            this.defaultProduct,
          ],
        },
      },
    };
  }

  /**
   * Returns object for "kreditz" step. This
   * object can be pushed to the dataLayer.
   *
   * @returns {{event: String, ecommerce: {Object}}}
   */
  kreditz() {
    const kreditzEecObject = {
      event: 'eec.kreditz',
      visitorType: this.customerType,
      category: 'Ecommerce',
      action: 'Checkout step 3b',
      label: '',
      products: [
        this.defaultProduct,
      ],
    };

    if (this.loanType === 'businessInstalmentLoan') {
      // Return kreditzEecObject with businessLoan specific overrides
      return {
        ...kreditzEecObject,
        event: 'eec.b2b.kreditz',
        action: 'Checkout step 4b',
        products: [
          this.defaultBusinessLoanProduct,
        ],
      };
    }
    // Return default Eec object
    return kreditzEecObject;
  }

  /**
   * Returns object for "reject" step. This
   * object can be pushed to the dataLayer.
   *
   * @returns {{event: String, visitorType: String, default dimensions}}
   */
  reject() {
    return {
      event: this.key,
      visitorType: this.customerType,
      ...this.defaultDimensions,
    };
  }

  add() {
    const amounts = {
      amount: '',
      totalAmount: '',
      variant: '',
    };

    if (this.formData.loanParams.withdrawalAmount) {
      amounts.totalAmount = this
        .formData.loanParams.withdrawalAmount > 50000 ? '2000.00' : '500.00';
      amounts.variant = DataLayer.getInEuros(this.formData.loanParams.withdrawalAmount);
      amounts.amount = DataLayer.getInEuros(this.formData.loanParams.withdrawalAmount);
    } else {
      amounts.amount = DataLayer.getInEuros(this.formData.loanParams.appliedLoanLimit);
      amounts.totalAmount = DataLayer.getInEuros(this.formData.loanParams.appliedLoanLimit);
      amounts.variant = DataLayer.getInEuros(this.formData.loanParams.appliedInstalmentAmount);
    }

    const add = {
      event: 'eec.add',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          actionField: { list: 'Verkkohakemus – Landing page' },
          products: [{
            name: DataLayer.loanTypeInFinnish(this.formData.loanParams.type),
            price: amounts.amount,
            category: this.formData.loanParams.type,
            variant: amounts.variant,
            brand: this.formData.loanParams.brand.split('.')[0].toLowerCase(),
            dimension10: this.formData.loanParams.type,
            dimension11: DataLayer.loanTypeInFinnish(this.formData.loanParams.type),
            dimension12: this.formData.loanParams.brand.split('.')[0].toLowerCase(),
            dimension13: amounts.amount,
            dimension14: amounts.variant,
            dimension15: this.formData.loanParams.type === 'continuousLoan' ? amounts.amount : '',
            dimension16: amounts.totalAmount,
          }],
        },
      },
    };
    return add;
  }

  addB2c() {
    const add = {
      event: 'eec.add',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          actionField: { list: 'B2C – Landing page' },
          products: [{
            ...this.defaultProduct,
          }],
        },
      },
    };
    return add;
  }

  detail() {
    const detail = {
      event: 'eec.detail',
      ecommerce: {
        currencyCode: 'EUR',
        detail: {
          actionField: { list: 'Verkkohakemus – Landing page' },
          products: [],
        },
      },
    };

    this.formData.products.forEach((product) => {
      const productObject = {
        name: DataLayer.loanTypeInFinnish(product.type),
        price: DataLayer.getInEuros(product.amount.amount),
        category: product.type,
        variant: '',
        brand: product.product.split('.')[0].toLowerCase(),
        dimension10: product.type,
        dimension11: DataLayer.loanTypeInFinnish(product.type),
        dimension12: product.product.split('.')[0].toLowerCase(),
        dimension13: DataLayer.getInEuros(product.amount.amount),
        dimension14: '',
        dimension15: DataLayer.getInEuros(product.amount.amount),
        dimension16: '',
        dimension50: '',
        dimension51: '',
        dimension52: '',
        dimension53: '',
        dimension54: '',
        dimension55: '',
        dimension56: '',
        dimension57: '',
        dimension58: '',
      };

      if (product.type === 'continuousLoan') {
        productObject.dimension16 = product.amount.amount > 50000 ? '2000.00' : '500.00';
        productObject.variant = DataLayer.getInEuros(product.amount.amount);
      } else {
        productObject.dimension16 = DataLayer.getInEuros(product.amount.amount);
        productObject.variant = DataLayer.getInEuros(
          product.amount.instalments[product.instalmentAmount].amount,
        );
        productObject.dimension14 = DataLayer.getInEuros(
          product.amount.instalments[product.instalmentAmount].amount,
        );
      }

      detail.ecommerce.detail.products.push(productObject);
    });
    return detail;
  }

  /**
   * Returns object for "loanOffer" step. This
   * object can be pushed to the dataLayer.
   *
   * @returns {{event: String, category: string, action: string, label: string, products: array}}
   */
  loanOffer() {
    return {
      event: this.event,
      category: 'Ecommerce',
      action: 'Checkout step 3.5',
      label: '',
      products: [
        this.defaultProduct,
      ],
    };
  }

  /**
   * Customer Questions additional details to be pushed when submit is possible
   */
  infoExtra() {
    const infoExtra = {
      accommodation: this.formData.accommodation,
      accommodationType: this.formData.accommodationType,
      education: this.formData.education,
      workSituation: this.formData.workSituation,
      workRelationShipType: this.formData.workRelationshipType || '',
      personalIncome: this.formData.personalIncome,
      householdIncomeCombined: this.formData.householdIncomeCombined,
      assetsTotal: DataLayer.getInEuros(this.formData.assetsTotal),
      otherExpenses: DataLayer.getInEuros(this.formData.otherExpenses),
      loansTotal: DataLayer.getInEuros(this.formData.loansTotal),
      purpose: this.formData.purpose,
      sizeOfFamily: this.formData.sizeOfFamily,
      instalmentPerMonth: DataLayer.getInEuros(this.formData.instalmentPerMonth),
      event: 'infoExtra',
      loanTypes: [],
      incomeTypes: [],
    };

    const loanTypes = [
      'mortgage',
      'carLoan',
      'consumerLoan',
      'creditCardLoan',
      'otherLoan',
      'paydayLoan',
      'studentLoan',
    ];
    loanTypes.forEach((key) => {
      if (this.formData[key] === 'on') {
        infoExtra.loanTypes.push(key);
      }
    });

    const incomeTypes = [
      'monthlyIncomeOther',
      'monthlyIncomeWelfare',
      'pension',
      'rentalIncome',
      'salary',
      'studentAllowance',
      'unemploymentAid',
    ];
    incomeTypes.forEach((key) => {
      if (this.formData[key] === 'on') {
        infoExtra.incomeTypes.push(key);
      }
    });

    return infoExtra;
  }

  /**
   * Returns event properties for unsuccessful validation
   */
  stepValidationClick() {
    return {
      event: 'stepValidationClick',
      ...this.validationProperties,
    };
  }

  /**
   * Returns event properties for successful validation
   */
  stepValidationSubmit() {
    return {
      event: 'stepValidationSubmit',
      ...this.validationProperties,
    };
  }

  /**
   * Returns b2b event properties for unsuccessful validation
   */
  b2bStepValidationClick() {
    const event = {
      event: 'b2b.stepValidationClick',
      ...this.b2bValidationProperties,
    };
    if (this.formData) {
      const appliedLoanLimit = DataLayer.getInEuros(this.formData.appliedLoanLimit);
      event.dimension13 = appliedLoanLimit;
      event.dimension16 = appliedLoanLimit;
      event.dimension17 = this.formData.appliedInstalmentCount;
    }
    return event;
  }

  /**
   * Returns b2b event properties for successful validation
   */
  b2bStepValidationSubmit() {
    const event = {
      event: 'b2b.stepValidationSubmit',
      ...this.b2bValidationProperties,
    };
    if (this.formData) {
      const appliedLoanLimit = DataLayer.getInEuros(this.formData.appliedLoanLimit);
      event.dimension13 = appliedLoanLimit;
      event.dimension16 = appliedLoanLimit;
      event.dimension17 = this.formData.appliedInstalmentCount;
    }
    return event;
  }

  /**
   * Returns the clicked offer details together with the initially applied details
   */
  productClick() {
    const offer = this.offers.find(selected => selected.id === this.formData.id);
    const offerIndex = this.offers.indexOf(offer);
    return {
      event: 'eec.productClick',
      ecommerce: {
        click: {
          actionField: { list: 'Offered - Lainatarjous' },
          products: [{
            ...this.defaultProduct,
            dimension20: offer.type, // offered loan type
            dimension21: DataLayer.loanTypeInFinnish(offer.type), // offered loan type in Finnish
            dimension22: DataLayer.offerBrand(offer.loanProgramName), // offered brand
            dimension23: DataLayer.getInEuros(offer.loanLimit), // offered loan limit in euros
            dimension24: DataLayer.getInEuros(offer.loanLimit), // offered instalment amount
            dimension25: '',
            dimension26: DataLayer.getInEuros(offer.loanLimit), // offered loan limit
            dimension27: offer.numberOfInstalments, // number of offered instalments
            dimension28: DataLayer.getInEuros(offer.instalmentAmount), // offered instalment amount
            price: DataLayer.getInEuros(offer.loanLimit),
            position: offerIndex + 1,
            variant: DataLayer.getInEuros(offer.instalmentAmount),
          }],
        },
      },
    };
  }

  /**
   * Returns the clicked b2b loan offer details together with the initially applied details
   */
  productClickB2b() {
    const offer = this.offers.find(selected => selected.id === this.formData.id);
    const offerIndex = this.offers.indexOf(offer);
    return {
      event: 'eec.b2b.productClick',
      ecommerce: {
        click: {
          actionField: { list: 'Offered - businessInstalmentLoan' },
          products: [{
            ...this.defaultBusinessLoanProduct,
            dimension20: 'businessInstalmentLoan',
            dimension21: 'yrityslaina',
            dimension22: DataLayer.offerBrand(offer.loanProgramName),
            dimension23: DataLayer.getInEuros(offer.loanLimit),
            dimension24: DataLayer.getInEuros(offer.instalmentAmount),
            dimension25: '',
            dimension26: DataLayer.getInEuros(offer.loanLimit),
            dimension27: offer.numberOfInstalments,
            dimension28: DataLayer.getInEuros(offer.instalmentAmount),
            price: DataLayer.getInEuros(offer.loanLimit),
            position: offerIndex + 1,
            variant: offer.numberOfInstalments,
          }],
        },
      },
    };
  }

  /**
   * impressions are the loan offers together with the initially applied details
   */
  impressions() {
    const impressions = {
      event: 'eec.impressionView',
      ecommerce: {
        currencyCode: 'EUR',
        impressions: [],
      },
    };
    this.offers.forEach((offer, index) => {
      impressions.ecommerce.impressions.push(
        {
          ...this.defaultProduct,
          dimension50: offer.type, // offered loan type
          dimension51: DataLayer.loanTypeInFinnish(offer.type), // offered loan type in Finnish
          dimension52: DataLayer.offerBrand(offer.loanProgramName), // offered brand
          dimension53: DataLayer.getInEuros(offer.loanLimit), // offered loan limit in euros
          dimension54: DataLayer.getInEuros(offer.loanLimit), // offered instalment amount
          dimension55: '',
          dimension56: DataLayer.getInEuros(offer.loanLimit), // offered loan limit
          dimension57: offer.numberOfInstalments, // number of offered instalments
          dimension58: DataLayer.getInEuros(offer.instalmentAmount), // offered instalment amount
          price: DataLayer.getInEuros(offer.loanLimit),
          variant: DataLayer.getInEuros(offer.instalmentAmount),
          list: 'Offered - Lainatarjous',
          position: index + 1,
        },
      );
    });
    return impressions;
  }

  /**
   * impressions are the loan offers together with the initially applied details
   */
  impressionsB2b() {
    const impressions = {
      event: 'eec.b2b.impressionView',
      ecommerce: {
        currencyCode: 'EUR',
        impressions: [],
      },
    };
    this.offers.forEach((offer, index) => {
      impressions.ecommerce.impressions.push(
        {
          ...this.defaultBusinessLoanProduct,
          dimension50: 'businessInstalmentLoan',
          dimension51: 'yrityslaina',
          dimension52: DataLayer.offerBrand(offer.loanProgramName),
          dimension53: DataLayer.getInEuros(offer.loanLimit),
          dimension54: DataLayer.getInEuros(offer.instalmentAmount),
          dimension55: '',
          dimension56: DataLayer.getInEuros(offer.loanLimit),
          dimension57: offer.numberOfInstalments,
          dimension58: DataLayer.getInEuros(offer.instalmentAmount),
          price: DataLayer.getInEuros(offer.loanLimit),
          variant: offer.numberOfInstalments,
          list: 'Offered - businessInstalmentLoan',
          position: index + 1,
        },
      );
    });
    return impressions;
  }

  /**
   * Returns object for "instalmentLoanTerms" step. This
   * object can be pushed to the dataLayer.
   *
   * @returns {{event: String, ecommerce: {Object}}}
   */
  instalmentLoanTerms() {
    let product = this.defaultProduct;

    if (this.key !== 'checkout_4') {
      product = {
        ...this.defaultProduct,
        ...this.additionalDimensions,
        name: this.selectedOfferProductName || this.defaultProduct.name,
        price: this.selectedLoanLimit,
        variant: this.selectedInstalmentAmount,
      };
    }
    return {
      event: this.eventForCheckout,
      visitorType: this.customerType, // New or old customer
      ecommerce: {
        [this.keyForCheckout]: { // Key: checkout
          actionField: this.actionField,
          products: [
            product,
          ],
        },
      },
    };
  }

  /**
   * Returns object for "continuousLoanTerms" step. This
   * object can be pushed to the dataLayer.
   *
   * @returns {{event: String, ecommerce: {Object}}}
   */
  continuousLoanTerms() {
    let product = {};
    if (!this.initialData.isSessionIdentified) {
      product = this.defaultProduct;
    } else {
      product = {
        ...this.defaultProduct,
        ...this.additionalDimensions,
        name: this.selectedOfferProductName || this.defaultProduct.name,
        dimension23: '', // N/A
        dimension27: '', // N/A
        dimension28: '', // N/A
      };
    }
    return {
      event: this.eventForCheckout,
      visitorType: this.customerType, // New or old customer
      ecommerce: {
        [this.keyForCheckout]: { // Key: checkout
          actionField: this.actionField,
          products: [
            product,
          ],
        },
      },
    };
  }

  /**
   * Returns object for "complete" step. This
   * object can be pushed to the dataLayer.
   *
   * @returns {{event: String, ecommerce: {Object}}}
   */
  complete() {
    /**
     * Check if purchase event is set to be pushed to prevent duplicate events
     */
    if (!this.purchase) {
      return null;
    }
    const product = {
      ...this.defaultProduct,
      ...this.additionalDimensions,
      name: this.selectedOfferProductName || this.defaultProduct.name,
      price: this.revenue, // final revenue
      variant: this.finalVariant, // final variant
      category: this.selectedOffer.type, // final category
      dimension23: this.revenue, // Final revenue
      dimension25: this.withdrawalAmount, // Selected withdrawal amount
      dimension30: this.deliveryFee,
      dimension31: this.paymentSchedule, // selected payment schedule
      dimension32: this.initialData.invoicingMethod, // selected invoicingMethod
    };

    return {
      event: this.event,
      visitorType: this.customerType, // New or old customer
      ecommerce: {
        currencyCode: 'EUR',
        [this.key]: { // Key: purchase
          actionField: this.actionField,
          products: [
            product,
          ],
        },
      },
    };
  }

  instalmentLoanComplete() {
    return this.complete();
  }
  // ========== ACTIONS (STEPS) ENDS ==========

  // ========== BUSINESS LOAN STEPS BEGIN =========

  /**
   * Eec.detail event for businessInstalmentLoan product
   */
  businessLoanDetail() {
    const appliedLoanLimit = DataLayer.getInEuros(this.formData.appliedLoanLimit);
    return {
      event: 'eec.b2b.detail',
      ecommerce: {
        currencyCode: 'EUR',
        detail: {
          actionField: {
            list: 'BusinessInstalmentLoan – Landing page',
          },
          products: [{
            ...this.defaultBusinessLoanProduct,
            price: appliedLoanLimit,
            variant: this.formData.appliedInstalmentCount,
            dimension13: appliedLoanLimit,
            dimension16: appliedLoanLimit,
            dimension17: this.formData.appliedInstalmentCount,
          }],
        },
      },
    };
  }

  /**
   * Signee start event for businessInstalmentLoan
   */
  businessLoanSigneeStart() {
    return {
      event: 'eec.b2b.checkoutSignature',
      ecommerce: {
        currencyCode: 'EUR',
        checkout: {
          actionField: {
            step: 1,
          },
          products: [
            this.defaultBusinessLoanProduct,
          ],
        },
      },
    };
  }

  /**
   * businessInstalmentLoan Checkout step 1 and 2
   * Both happen in the same route but the step is based on user's identification status
   */
  businessLoanCompanySelection() {
    // set checkout step based on if session is identified
    const checkoutObject = this.defaultBusinessLoanCheckoutObject;
    checkoutObject.ecommerce.checkout.actionField.step = !this.initialData.isSessionIdentified
      ? 1 : 2;
    return checkoutObject;
  }

  /**
   * businessInstalmentLoan Checkout step 2 (DROP)
   * No company connections
   */
  businessLoanNoCompanyConnections() {
    const checkoutObject = this.defaultBusinessLoanCheckoutObject;
    checkoutObject.ecommerce.checkout.actionField.step = 2;
    return checkoutObject;
  }

  /**
   * businessInstalmentLoan Checkout step 3
   */
  businessLoanCompanyInformation() {
    const checkoutObject = this.defaultBusinessLoanCheckoutObject;
    checkoutObject.ecommerce.checkout.actionField.step = 3;
    return checkoutObject;
  }

  /**
   * businessInstalmentLoan Checkout step 4
   */
  businessLoanApplicant() {
    const checkoutObject = this.defaultBusinessLoanCheckoutObject;
    checkoutObject.ecommerce.checkout.actionField.step = 4;
    checkoutObject.loanPurpose = this.initialData.purpose;
    checkoutObject.visitorRevenue = DataLayer.getInEuros(this.initialData.turnoverInLastMonth);
    checkoutObject.visitorProfit = DataLayer.getInEuros(this.initialData.profitInLastMonth);
    return checkoutObject;
  }

  /**
   * businessInstalmentLoan Checkout step 5
   */
  businessLoanOffer() {
    const checkoutObject = this.defaultBusinessLoanCheckoutObject;
    checkoutObject.ecommerce.checkout.actionField.step = 5;
    return checkoutObject;
  }

  /**
   * businessInstalmentLoan Checkout step 6
   */
  businessLoanSignees() {
    const checkoutObject = this.defaultBusinessLoanCheckoutObject;
    checkoutObject.ecommerce.checkout.actionField.step = 6;
    checkoutObject.ecommerce.checkout.products = [{
      ...this.defaultBusinessLoanProduct,
      ...this.businessLoanAdditionalDimensions,
    }];
    return checkoutObject;
  }

  /**
   * businessInstalmentLoan Checkout step 1, 2 or 7 depending on processRole and identification
   */
  businessLoanTerms() {
    const checkoutObject = this.defaultBusinessLoanCheckoutObject;
    let step = 7;
    if (!this.initialData.isSessionIdentified) {
      step = 1;
    } else if (this.initialData.processRole === 'signee') {
      step = 2;
    }
    checkoutObject.ecommerce.checkout.actionField.step = step;
    checkoutObject.ecommerce.checkout.products = [{
      ...this.defaultBusinessLoanProduct,
      ...this.businessLoanAdditionalDimensions,
    }];
    return checkoutObject;
  }

  /**
   * businessInstalmentLoan Purchase event
   */
  businessLoanComplete() {
    if (!this.purchase) {
      return null;
    }

    return {
      event: 'eec.b2b.purchaseComplete',
      visitorType: this.customerType,
      ecommerce: {
        currencyCode: 'EUR',
        purchase: {
          actionField: {
            id: this.loanId,
            revenue: this.selectedLoanLimit,
          },
          products: [{
            ...this.defaultBusinessLoanProduct,
            ...this.businessLoanAdditionalDimensions,
            price: this.selectedLoanLimit,
            variant: this.selectedNumberOfInstalments,
            dimension30: this.deliveryFeeTotal,
          }],
        },
      },
    };
  }

  /**
   * businessInstalmentLoan Purchase event when required signers have signed
   */
  businessLoanSigningComplete() {
    if (!this.purchase) {
      return null;
    }

    return {
      event: 'eec.b2b.purchaseCompleteExtra',
      visitorType: this.customerType,
      ecommerce: {
        currencyCode: 'EUR',
        purchase: {
          actionField: {
            id: this.loanId,
            revenue: this.selectedLoanLimit,
          },
          products: [{
            ...this.defaultBusinessLoanProduct,
            ...this.businessLoanAdditionalDimensions,
            price: this.selectedLoanLimit,
            variant: this.selectedNumberOfInstalments,
            dimension30: this.deliveryFeeTotal,
          }],
        },
      },
    };
  }

  /**
   * businessInstalmentLoan event when complete but required signers are pending
   */
  businessLoanSigningPending() {
    return {
      event: 'eec.b2b.purchaseWaitingExtra',
      visitorType: this.customerType,
      products: [{
        ...this.defaultBusinessLoanProduct,
        ...this.businessLoanAdditionalDimensions,
        dimension30: this.deliveryFeeTotal,
      }],
    };
  }

  /**
   * businessInstalmentLoan event when complete but required signers are pending
   */
  businessLoanPending() {
    return {
      event: 'eec.b2b.purchaseWaiting',
      visitorType: this.customerType,
      products: [{
        ...this.defaultBusinessLoanProduct,
        ...this.businessLoanAdditionalDimensions,
        dimension30: this.deliveryFeeTotal,
      }],
    };
  }

  /**
   * businessInstalmentLoan rejected event
   */
  businessLoanRejected() {
    return {
      event: 'eec.b2b.declined',
      visitorType: this.customerType,
      productName: 'yrityslaina',
      productPrice: this.appliedLoanLimit,
      productCategory: 'businessInstalmentLoan',
      productVariant: this.appliedInstalmentCount,
      productBrand: 'saldo',
      ...this.businessLoanDefaultDimensions,
    };
  }

  /**
   * businessInstalmentLoan add to cart event
   */
  addToCartB2b() {
    const appliedLoanLimit = DataLayer.getInEuros(this.formData.appliedLoanLimit);
    return {
      event: 'eec.b2b.add',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          actionField: { list: 'BusinessInstalmentLoan – Landing page' },
          products: [{
            ...this.defaultBusinessLoanProduct,
            price: appliedLoanLimit,
            variant: this.formData.appliedInstalmentCount,
            dimension13: appliedLoanLimit,
            dimension16: appliedLoanLimit,
            dimension17: this.formData.appliedInstalmentCount,
          }],
        },
      },
    };
  }

  /**
   * businessInstalmentLoan offers event
   */
  loanOffersB2b() {
    return {
      event: 'eec.b2b.loanOffers',
      visitorType: this.customerType,
      category: 'Ecommerce',
      action: 'Checkout step 5',
      label: '',
      products: [{
        ...this.defaultBusinessLoanProduct,
      }],
    };
  }
}

export default DataLayer;
